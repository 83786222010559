import React from 'react'
import { classNames } from 'utils/utils'

const PrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
        <button
            className={classNames(
                'z-10 !flex items-center !left-0 !w-auto',
                className
            )}
            onClick={onClick}
        >
            <span className="left-arrow px-2.5 font-attaleck leading-8 text-[100px] text-white">
                &lsaquo;
            </span>
        </button>
    )
}

const NextArrow = (props) => {
    const { className, style, onClick } = props
    return (
        <button
            className={classNames(
                'z-10 !flex items-center !right-0 !w-auto',
                className
            )}
            onClick={onClick}
        >
            <span className="right-arrow font-attaleck text-[100px] leading-8 text-white px-2.5">
                &rsaquo;
            </span>
        </button>
    )
}

export { PrevArrow, NextArrow }
