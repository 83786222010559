import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
const FILTER_CATEGORIES = [
    { type: 'all', buttonText: 'All Results' },
    { type: 'brand', buttonText: 'Brands' },
    { type: 'franchise', buttonText: 'Franchises' },
    { type: 'property', buttonText: 'Properties' },
    { type: 'page', buttonText: 'Articles' },
]
const Filters = ({ filter, handleFilter, searchResults }) => {
    return (
        <article className="flex w-[250px] p-[15px] flex-col items-start gap-2.5 rounded-[10px] border-2 border-blue self-start">
            {FILTER_CATEGORIES.map(({ type, buttonText }) => {
                // Check if there are any search results of the current category type
                const hasResultsOfType = searchResults?.some(
                    (result) => result.type === type
                )

                // Render the filter button only if there are search results of the current category type or if the category is 'all'
                if (hasResultsOfType || type === 'all') {
                    return (
                        <button
                            key={type}
                            className={`flex p-[5px] justify-center items-center gap-8 self-stretch rounded-[5px] border-none bg-[#0A85FF] hover:bg-[#0A85FF] hover:text-white ${
                                filter === type
                                    ? 'bg-[#0A85FF] text-white'
                                    : 'bg-white text-blue'
                            }`}
                            onClick={() => handleFilter(type)}
                        >
                            <span className="font-extrabold text-base font-openSans tracking-[1.6px] uppercase flex-grow flex-shrink-0 basis-0">
                                {buttonText}
                            </span>
                            <div className="flex px-[5px] flex-col justify-center items-center gap-[10px]">
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className="flex width-[9px] h-4 pr-.5 flex-col justify-center items-center text-white"
                                />
                            </div>
                        </button>
                    )
                }

                // If there are no search results of the current category type and it's not 'all', render nothing
                return null
            })}
        </article>
    )
}

export default Filters
