import React from 'react'
import useApi from 'hooks/useApi'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import { Link, useLocation } from 'react-router-dom'
import DefaultTextOverlay from 'pages/franchise/components/DefaultTextOverlay/DefaultTextOverlay'
import { sortAlphabetically } from 'utils/utils'
const CollectionPage = () => {
    const location = useLocation()

    const {
        responseJSON: content,
        isLoading: contentIsLoading,
        error: contentError,
        restrictedContentError,
    } = useApi(
        `collection?slug=${encodeURIComponent(
            location.pathname.replace('/franchise/collections/', '')
        )}&${location.search.replace('?', '')}`,
        'get'
    )

    if (content.length === 0) return

    // Filter by Franchises
    const franchises = content.structured?.properties?.filter(
        (property) => property.type === 'franchise'
    )

    const properties = content.structured?.properties.filter(
        (property) => property.type !== 'franchise'
    )

    const groupedProperties = properties?.reduce((grouped, property) => {
        let { media_type } = property
        if (media_type === null) {
            media_type = 'misc'
        }
        if (!grouped[media_type]) {
            grouped[media_type] = []
        }
        grouped[media_type].push(property)
        return grouped
    }, {})

    const sortedGroupedProperties = Object.entries(groupedProperties).sort(
        ([categoryA], [categoryB]) => {
            if (categoryA === 'misc') return 1
            if (categoryB === 'misc') return -1
            return categoryA.localeCompare(categoryB)
        }
    )

    const renderRow = ({ name, id, slug, featured_image }) => {
        return (
            <div
                key={id}
                className="h-[175px] w-[117px] relative hover:opacity-90"
            >
                <Link to={slug}>
                    <img
                        src={featured_image}
                        alt={name}
                        className="object-cover w-full h-full"
                    ></img>
                    {featured_image?.endsWith('default_featuredImage.png') && (
                        <DefaultTextOverlay fontSize={10} name={name} />
                    )}
                </Link>
            </div>
        )
    }

    const renderRows = ([mediaType, properties]) => {
        if (!properties) return null

        return (
            <div
                className="flex flex-col items-start gap-2.5 self-stretch"
                key={mediaType}
            >
                <h3 className="self-stretch text-xl font-extrabold text-white uppercase font-openSans">
                    {mediaType}
                </h3>
                <div className="flex items-start gap-2.5 flex-wrap content-start self-stretch">
                    {sortAlphabetically(properties).map((property) =>
                        renderRow(property)
                    )}
                </div>
            </div>
        )
    }

    return (
        <main className="bg-[#080918] collection-page z-0">
            <section
                className={`w-full h-[18.75rem] before:block before:absolute before:inset-0 relative overflow-hidden`}
            >
                {content.structured?.hero_image_url && (
                    <div className="inset-0 -z-10 ">
                        <img
                            src={content.structured?.hero_image_url}
                            className="absolute object-cover w-full h-full opacity-40"
                        />
                    </div>
                )}
                <h1 className="relative z-10 flex items-center justify-center h-full text-5xl font-extrabold text-center text-white font-openSans">
                    {content.name}
                </h1>
            </section>
            {/* Body */}
            <section className="sm:px-10 pt-12.5 w-full flex flex-col items-center self-stretch">
                {/* Content Container */}
                <Breadcrumbs
                    className="self-start pb-5 pl-0"
                    content={content}
                />
                <div className="flex flex-col items-center self-stretch gap-10 px-10 pt-10 pb-20 border-t border-[#757575] rounded-none">
                    {/* Intro */}
                    <div className="p-10 bg-[rgba(255,255,255,0.05)] flex flex-col items-center self-stretch gap-[25px] text-white rounded-[10px]">
                        <h2 className="self-stretch text-3xl font-extrabold uppercase font-openSans">
                            {content.name}
                        </h2>
                        <p className="font-wbs font-bold leading-[1.375rem] self-stretch text-[#fff] text-justify">
                            {content.description}
                        </p>
                    </div>
                    {/* Media */}
                    <div className="flex flex-col items-start self-stretch">
                        {/* FRANCHISES */}
                        {franchises?.length > 0 && (
                            <div className="flex flex-col items-start self-stretch">
                                <h3 className="self-stretch text-xl font-extrabold text-white uppercase border-b border-[#757575] font-openSans py-2.5">
                                    Franchises
                                </h3>
                                <div className="flex items-start gap-2.5 flex-wrap content-start self-stretch py-[60px]">
                                    {sortAlphabetically(franchises).map(
                                        (franchise) => renderRow(franchise)
                                    )}
                                </div>
                            </div>
                        )}
                        {/* PROPERTIES */}
                        {properties?.length > 0 && (
                            <div className="flex flex-col items-start self-stretch gap-y-[60px] pb-[60px]">
                                <h3 className="self-stretch text-xl font-extrabold text-white uppercase border-b border-[#757575] font-openSans py-2.5">
                                    Properties
                                </h3>
                                {sortedGroupedProperties.map(
                                    (groupedProperties) =>
                                        renderRows(groupedProperties)
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CollectionPage
