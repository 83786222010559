import { useEffect, forwardRef, useState } from 'react'
import { flat } from 'utils/utils'
import { DebounceInput } from 'react-debounce-input'
import { useAtom, useAtomValue } from 'jotai'
import { navAtom, matchesAtom, searchTermAtom } from 'utils/atoms'
import { Link, useNavigate } from 'react-router-dom'
import './AnimatedSearch.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

const AnimatedSearch = forwardRef(function (props, ref) {
    // const [term, setTerm] = useState('')
    // const [matches, setMatches] = useState([])
    const [matches, setMatches] = useAtom(matchesAtom)
    const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)
    const [isInputOpen, setIsInputOpen] = useState(false)

    const nav = useAtomValue(navAtom)
    const navigate = useNavigate()

    useEffect(() => {
        if (searchTerm.length < 2) {
            setMatches([])
            return
        }

        const searchableNav = flat(nav)
        const matched = searchableNav.filter((i) => {
            const regex = new RegExp(
                '(?=.*' + searchTerm.split(/\W+/g).join(')(?=.*') + ')',
                'i'
            )

            return regex.test(i.title)
        })

        setMatches(matched)
    }, [searchTerm, nav])

    const handleSubmit = (event) => {
        event.preventDefault()
        if (searchTerm.length >= 2) {
            navigate(
                `/franchise/search?query=${encodeURIComponent(searchTerm)}`
            )
            setSearchTerm('')
        }
    }

    return (
        <div className="animatedsearch-container franchiseNav-buttons">
            <form
                className="relative flex items-center justify-end mx-auto w-max group/search"
                onSubmit={handleSubmit}
            >
                <DebounceInput
                    onFocus={() => setIsInputOpen(true)}
                    onBlur={() => setIsInputOpen(false)}
                    inputRef={ref}
                    minLength={2}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    debounceTimeout={500}
                    className="relative z-10 w-12 h-10 pr-12 rounded-full outline-none cursor-pointer focus:bg-white peer focus:cursor-text focus:w-full focus:border-white focus:border focus:pl-12 search-input "
                />
                <button
                    title="Search"
                    className="absolute flex my-auto ml-2 text-3xl search-icon peer-focus:left-1"
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className={`${
                            isInputOpen ? 'z-10 invert' : ''
                        } group-hover/search:opacity-[.85]`}
                    />
                </button>
            </form>
            <div className="relative z-20 mt-2 matches">
                {matches.map((match, idx) => {
                    return (
                        <div key={idx}>
                            <Link
                                to={match.slug}
                                onClick={() => setSearchTerm('')}
                                className="text-xs hover:text-seekerblue"
                            >
                                {match.title}
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})
export default AnimatedSearch
