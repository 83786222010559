import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useApi from 'hooks/useApi'
import { useLocation, useParams } from 'react-router-dom'
import './ArticlePage.scss'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'
import { ReactComponent as CaretRightIcon } from '__shared/images/icons/caret.svg'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import RestrictedContent from 'components/RestrictedContent/RestrictedContent'
import PreviewLink from '../brand-franchisePage/components/PreviewLink/PreviewLink'

const ArticlePage = ({ guidelines }) => {
    const location = useLocation()
    const isPreviewPage = location.pathname.includes('/preview')
    const [url, setUrl] = useState('')
    const [fileSize, setFileSize] = useState(null)
    const { brand, franchise, property, article, page } = useParams()
    const [guidelineCategories, setGuidelineCategories] = useState([])
    const { currentFranchiseArticles } = useGetCurrentLevelNav()

    const fileName = url
        ?.substring(url?.lastIndexOf('/') + 1)
        .replace('/(.pdf|.mp4|.pptx)/i', '')

    useEffect(() => {
        if (!currentFranchiseArticles) return
        setGuidelineCategories(
            Object.keys(currentFranchiseArticles).filter((category) =>
                category.toLowerCase().endsWith('guidelines')
            )
        )
    }, [currentFranchiseArticles])

    useEffect(() => {
        if (!isPreviewPage) return

        const url = decodeURIComponent(
            new URLSearchParams(location.search).get('p')
        )

        setUrl(url)

        const getFileSize = async () => {
            try {
                const response = await fetch(url)
                const contentLength = response.headers.get('content-length')

                setFileSize(formatFileSize(contentLength))
            } catch (error) {
                console.error('Error fetching file size: ', error)
            }
        }

        const formatFileSize = (sizeInBytes) => {
            const sizeInKB = sizeInBytes / 1024
            if (sizeInKB < 1024) {
                return parseInt(sizeInKB) + ' KB'
            } else {
                const sizeInMB = sizeInKB / 1024
                return parseInt(sizeInMB) + ' MB'
            }
        }

        getFileSize()
    }, [location.search])

    const {
        responseJSON: content,
        isLoading,
        restrictedContentError,
    } = useApi(
        `page?slug=${encodeURIComponent(
            location.pathname
                .replace('/franchise/', '')
                .replace(isPreviewPage ? '/preview' : '', '')
        )}&${location.search.replace('?', '')}`,
        'get'
    )
    const getHeaderTitle = () => {
        let title = property || franchise || brand || page
        return title.replaceAll('-', ' ')
    }

    const download_file = async (fileURL) => {
        const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1)

        let response = await fetch(url)
        let blobData = await response.blob()
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blobData)
        a.setAttribute('download', filename)
        a.click()
    }

    return (
        <div className="flex flex-col items-start flex-grow w-full bg-wbslate article-page">
            {/* HERO */}
            {restrictedContentError ? (
                <section
                    className="hero flex h-[300px] items-center gap-2.5 self-stretch relative "
                    style={{
                        backgroundImage: `url(${content?.featured_image})`,
                        // backgroundPosition: '0px -5.06px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Background overlay with 60% opacity */}
                    <div className="absolute inset-0 opacity-60 bg-wbslate"></div>

                    <div className="flex sm:px-12.5 flex-col items-center flex-grow flex-shrink-0 basis-0 text-white relative isolate z-10">
                        <h1 className="text-5xl font-extrabold text-center uppercase font-openSans flex w-[637px] h-[118px] flex-col justify-center z-40">
                            Restricted Content
                        </h1>
                    </div>
                </section>
            ) : content?.data?.introduction?.image?.source ? (
                <section
                    className="hero flex h-[300px] items-center gap-2.5 self-stretch relative "
                    style={{
                        backgroundImage: `url(${content?.data?.hero_image?.source})`,
                        // backgroundPosition: '0px -5.06px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Background overlay with 60% opacity */}
                    <div className="absolute inset-0 opacity-60 bg-wbslate"></div>

                    <div className="flex sm:px-12.5 flex-col items-center flex-grow flex-shrink-0 basis-0 text-white relative isolate z-10">
                        <h1 className="text-5xl font-extrabold text-center uppercase font-openSans flex w-[637px] h-[118px] flex-col justify-center z-40">
                            {getHeaderTitle()}{' '}
                            {property
                                ? 'Property'
                                : franchise
                                ? 'Franchise'
                                : 'Brand'}
                        </h1>
                    </div>
                </section>
            ) : (
                <></>
            )}

            <section className="flex pt-12.5 px-10 flex-col items-center self-stretch flex-grow">
                {/* BREADCRUMBS */}
                <div className="flex items-center gap-2.5 self-stretch bg-white border-b border-[#999] h-20 ">
                    <Breadcrumbs
                        className="pl-[25px] text-black text-lg uppercase font-bold leading-[80px]"
                        // title="Active Grid"
                        content={content}
                        fileName={fileName}
                    />
                </div>

                {/* CONTENT CONTAINER */}
                <div className="flex py-10 px-[30px] items-center justify-end self-stretch border-b border-[#999] bg-white flex-grow">
                    {/* SIDENAV FILTERS */}

                    {/* CONTENT */}

                    {isLoading ? null : restrictedContentError ? (
                        <RestrictedContent />
                    ) : (
                        <div className="flex flex-col items-start flex-grow flex-shrink-0 gap-10 bg-white basis-0">
                            {guidelines ? (
                                guidelineCategories && (
                                    <div className="mx-auto max-w-8xl">
                                        <section className="pt-12">
                                            <div className="px-12 border-b-2">
                                                <h1 className="mt-3 mb-5 text-5xl font-extrabold tracking-tight text-[#555] font-openSans">
                                                    Guidelines
                                                </h1>
                                                <div className="py-12 lg:px-16 w-fit ">
                                                    <ul className="flex flex-col divide-y-2">
                                                        {guidelineCategories.map(
                                                            (guideline) => {
                                                                return (
                                                                    <li
                                                                        className="flex items-center hover:opacity-80 overall-guidelines-items"
                                                                        key={
                                                                            guideline
                                                                        }
                                                                    >
                                                                        <CaretRightIcon className="w-3 h-6 mr-6 caret-icon caret-icon-right" />

                                                                        <a
                                                                            className="text-3xl font-extrabold tracking-wide uppercase text-blue font-openSans"
                                                                            href={`#${guideline}`}
                                                                        >
                                                                            {' '}
                                                                            {
                                                                                guideline
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                        {guidelineCategories.map(
                                            (guideline) => {
                                                return (
                                                    <section
                                                        className="pt-12"
                                                        id={guideline}
                                                    >
                                                        <div className="px-12 border-b-2">
                                                            <h1 className="mt-3 mb-5 text-3xl font-extrabold tracking-tight text-[#555] font-openSans">
                                                                {guideline}
                                                            </h1>
                                                            <div className="pb-12 w-fit">
                                                                <ul className="flex flex-col list-disc list-inside guideline-list ">
                                                                    {currentFranchiseArticles[
                                                                        guideline
                                                                    ].map(
                                                                        (
                                                                            guideline
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        guideline.id
                                                                                    }
                                                                                    className="relative flex items-center hover:opacity-80 bullets"
                                                                                >
                                                                                    <Link
                                                                                        className="pl-8 text-2xl font-bold tracking-wide capitalize text-blue font-openSans"
                                                                                        to={`${guideline.slug}`}
                                                                                    >
                                                                                        {
                                                                                            guideline.title
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            }
                                        )}
                                    </div>
                                )
                            ) : isPreviewPage ? (
                                <div className="w-full px-12 py-12 overflow-scroll">
                                    <h1 className="mb-6 text-xl font-extrabold font-openSans text-[#555]">
                                        {fileName}
                                    </h1>
                                    <PreviewLink link={url} />
                                    <div className="flex justify-center">
                                        <button
                                            className="px-4 py-2 text-white rounded font-wbs bg-blue test"
                                            onClick={() => download_file(url)}
                                        >
                                            Download ({fileSize})
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full px-12 py-12 wp-content">
                                    <h1 className=" text-[#555]">
                                        {content?.post_title}
                                    </h1>
                                    <div
                                        className="w-full mx-auto wp-content max-w-8xl "
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                content && content?.post_content
                                                    ? content?.post_content
                                                    : '<h1>Coming Soon</h1>',
                                        }}
                                    />
                                    adfdasf
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

export default ArticlePage
