import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
const BtnContainer = ({ currentTab, setCurrentTab, tabButtonsList }) => {
    const location = useLocation()

    useEffect(() => {
        setCurrentTab('overview')
    }, [location.pathname])

    const handleTabClick = (title) => {
        setCurrentTab(title)
    }
    return (
        <div className="flex flex-wrap">
            {tabButtonsList?.map((name, index) => {
                return (
                    <button
                        key={index}
                        onClick={() => handleTabClick(name)}
                        className={`${
                            currentTab === name
                                ? 'border-b-[6px] border-[#0ABDE3]'
                                : 'border-b-[3px]  border-[#515961]'
                        } text-[#CCCCCC] text-lg sm:text-1xl md:text-[26px] leading-[26px] hover:text-white flex p-2.5 sm:p-5 md:p-[30px] gap-2.5 capitalize`}
                    >
                        <p
                            className={`${
                                currentTab === name ? 'text-[#0ABDE3]' : ''
                            }`}
                        >
                            {name}
                        </p>
                    </button>
                )
            })}
        </div>
    )
}

export default BtnContainer
