const FeedbackButton = () => {
    const handleButtonClick = () => {
        window.open('https://airtable.com/shrArcqv6NTaC8NHg', '_blank')
    }
    return (
        <button
            onClick={handleButtonClick}
            className="fixed p-1.5 text-[11px] font-bold cursor-pointer rounded-sm text-black bg-[#f5f5f5] border-t border-l bottom-0 right-0 z-10 font-arial"
        >
            Provide feedback
        </button>
    )
}

export default FeedbackButton
