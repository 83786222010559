import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '__shared/images/icons/house-user-solid.svg'
import { twMerge } from 'tailwind-merge'
import { useSetAtom } from 'jotai'
import { titleAtom } from 'utils/atoms'

const Breadcrumbs = ({ content = {}, fileName, className, title }) => {
    const setHeadTitle = useSetAtom(titleAtom)

    useEffect(() => {
        function addLastBreadcrumbToTitle(paths) {
            const lastCrumb = [...paths].pop()
            const { title, name } = lastCrumb
            setHeadTitle(title || name)
        }

        addLastBreadcrumbToTitle(newPaths)
        // Cleanup function
        return () => {
            setHeadTitle('')
        }
    }, [setHeadTitle])

    if (!content) return

    const myPaths = content?.structured?.nav
        ? Object.entries(content.structured.nav).sort((a, b) =>
              a[0].localeCompare(b[0])
          )
        : []

    addPath(myPaths)
    const newPaths = getPaths(myPaths)

    function addPath(myPaths) {
        let isBrandFranchisePropertyPage =
            content.post_type === 'franchises' ||
            content.post_type === 'brands' ||
            content.post_type === 'property'
        if (title) {
            myPaths.push(['title', { title: title }])
        } else if (!isBrandFranchisePropertyPage)
            myPaths.push([
                'page',
                { title: content?.post_title || content?.name || fileName },
            ])
    }

    function getPaths(array) {
        const result = []
        for (let [_, value] of array) {
            if (value) {
                result.push(value)
            }
        }
        return result
    }
    // let paths = location.pathname
    //     .replace('/c/', '/')
    //     .split('/')
    //     .filter((path) => path !== '')
    //     .filter((path) => path !== 'collections')
    //     .map((path) => path.replaceAll('-', ' '))

    const c = twMerge(
        'breadcrumbs relative z-20 flex items-center pl-12 text-lg font-bold breadcrumbs text-white font-wbs gap-x-2 text-sm',
        className
    )

    return (
        <div className={c}>
            <Link
                to={'/franchise'}
                className="flex items-center gap-x-[10px] paths hover:opacity-80 group"
            >
                {/* <HomeIcon className="w-4 fill-blue group-hover:opacity-80" /> */}
                Home
            </Link>
            {newPaths.map(({ name, title, slug, id }, index) => {
                const isLastPath = index === newPaths.length - 1
                return (
                    <React.Fragment key={index}>
                        <span className="">&#62;</span>

                        {isLastPath ? (
                            <span className=" last-path">{name || title}</span>
                        ) : (
                            <Link
                                to={slug}
                                className="flex items-center gap-x-[10px] paths hover:opacity-80"
                            >
                                {name || title}
                            </Link>
                        )}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default Breadcrumbs
