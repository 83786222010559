import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from 'utils/utils'
import WbLogo from '__shared/images/icons/wb-logo.png'
import './ProfileDropdown.scss'
import { useAtomValue } from 'jotai'
import { userInfoAtom } from 'utils/atoms'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
const userNavigation = [
    // { name: 'Products', href: '#' },
    // { name: 'Financials', href: '#' },
    // { name: 'Business plans', href: '#' },
    // { name: 'Resources', href: '#' },
    // { name: 'Favorites', href: '#' },
    { name: 'Support', link: '/support' },
]

const ProfileDropdown = () => {
    const userInfo = useAtomValue(userInfoAtom)
    const [isProfileOpen, setIsProfileOpen] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['authToken'])
    const navigate = useNavigate()

    return (
        <Menu as="div" className=" profile-dropdown">
            <div className="franchiseNav-buttons">
                <Menu.Button
                    title="User Profile"
                    className="flex items-center justify-center text-white rounded-full profile-icon focus:ring-1 group focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 hover:opacity-[.85]"
                >
                    <span className="sr-only">Open user menu</span>
                    <FontAwesomeIcon
                        icon={faUserCircle}
                        size="2xl"
                    ></FontAwesomeIcon>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <Menu.Items className="max-w-[281px] gap-y-5 flex flex-col absolute right-0 z-10 p-[9px] mt-5 font-extrabold tracking-widest uppercase bg-white shadow-lg top-full w-72 ring-1 ring-black ring-opacity-5 focus:outline-none ">
                    <div className="flex items-center pb-3 border-b border-black">
                        <div className="w-[100px] h-[100px] min-w-[100px]">
                            <img
                                src={WbLogo}
                                className="object-contain w-full h-full wb-logo invert"
                            />
                        </div>
                        <div className="flex-auto h-full overflow-hidden text-center">
                            <h2 className="font-wbSans text-[28px] font-bold leading-6 tracking-wider overflow-hidden text-ellipsis">
                                {userInfo?.display_name}
                            </h2>
                            <p className="font-bold uppercase text-[10px] text-ellipsis font-wbSans tracking-wider leading-4 overflow-hidden">
                                {userInfo?.user_email}
                            </p>
                        </div>
                    </div>
                    <Menu.Item className="py-0 text-blue ">
                        {({ active }) => (
                            <Link
                                to="#"
                                className="block px-5 font-extrabold leading-4 tracking-widest font-openSans"
                            >
                                My Account
                            </Link>
                        )}
                    </Menu.Item>
                    {userNavigation.map(({ name, link }) => (
                        <Menu.Item
                            key={name}
                            className="py-0 text-blue gap-y-[5px]"
                        >
                            {({ active }) => (
                                <Link
                                    to={link}
                                    className="block px-5 font-extrabold leading-4 tracking-widest font-openSans"
                                >
                                    {name}
                                </Link>
                            )}
                        </Menu.Item>
                    ))}
                    <Menu.Item className="py-0">
                        {({ active }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    removeCookie('authToken', {
                                        path: '/',
                                        maxAge: 36000,
                                        sameSite: 'none',
                                        secure: true,
                                        ...(window.location.hostname !==
                                            'localhost' && {
                                            domain: 'warnerbros.com',
                                        }),
                                    })
                                    navigate('/')
                                }}
                                className="block px-5 ml-auto font-extrabold leading-4 tracking-widest uppercase font-openSans text-blue"
                            >
                                Log out
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ProfileDropdown
