import { Link } from 'react-router-dom'
import './ActiveGridPage.scss'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import { useAtom } from 'jotai'
import { loadableFranchiseHomeAtom } from 'utils/atoms'

import useApi from 'hooks/useApi'

const ActiveGridPage = () => {
    const [franchiseHomeAtom] = useAtom(loadableFranchiseHomeAtom)

    const {
        responseJSON: content,
        isLoading: contentIsLoading,
        error: contentError,
    } = useApi(`active-grid`, 'get')

    const sortedProperties = (properties = []) => {
        return properties.sort((a, b) => {
            const priorityOrder = ['A', 'B', 'C', 'P', '']
            const priorityComparison =
                priorityOrder.indexOf(a.priority) -
                priorityOrder.indexOf(b.priority)

            if (priorityComparison !== 0) {
                return priorityComparison
            }

            return a.name.localeCompare(b.name)
        })
    }

    return (
        <div className="flex flex-col items-start w-full bg-wbslate ">
            {/* HERO */}
            {franchiseHomeAtom.data?.data?.introduction?.image?.source && (
                <section
                    className="hero flex h-[300px] items-center gap-2.5 self-stretch relative "
                    style={{
                        backgroundImage: `url(${franchiseHomeAtom.data?.data?.introduction?.image?.source})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Background overlay with 60% opacity */}
                    <div className="absolute inset-0 opacity-60 bg-wbslate"></div>

                    <div className="flex sm:px-12.5 flex-col items-center flex-grow flex-shrink-0 basis-0 text-white relative isolate z-10">
                        <h1 className="text-5xl font-extrabold text-center uppercase font-openSans flex w-[637px] h-[118px] flex-col justify-center z-40">
                            Active Grid
                        </h1>
                    </div>
                </section>
            )}

            {/* BODY */}
            <section className="flex pt-12.5 px-10 flex-col items-center self-stretch">
                {/* BREADCRUMBS */}
                <div className="flex items-center gap-2.5 self-stretch bg-white border-b border-[#999] h-20">
                    <Breadcrumbs
                        className="pl-[25px] text-black text-lg uppercase font-bold leading-[80px]"
                        title="Active Grid"
                    />
                </div>

                {/* CONTENT CONTAINER */}
                <div className="flex py-10 px-[30px] items-center justify-end self-stretch border-b border-[#999] bg-white">
                    {/* SIDENAV FILTERS */}

                    {/* CONTENT */}
                    <div className="flex flex-col items-start flex-grow flex-shrink-0 gap-10 bg-white basis-0">
                        {/* HEADING */}
                        <div className="flex items-center gap-[5px] self-stretch">
                            <h1 className="text-[#555] font-openSans text-3xl font-extrabold uppercase">
                                Active Licensing Grid
                            </h1>
                            <div className="flex flex-col justify-center items-end gap-[5px] flex-grow flex-shrink-0 basis-0">
                                <div className="flex items-center gap-[5px] self-stretch">
                                    <span className="flex-grow flex-shrink-0 basis-0 text-[#555] text-right font-openSans text-xs font-extrabold uppercase">
                                        tiers (franchise support level)
                                    </span>
                                    <div className="flex w-5 h-5 flex-col justify-center items-center gap-[5px] rounded-sm bg-[#EC3D3D]">
                                        <span className="text-white text-center text-[10px] font-bold tracking-[0.3px]">
                                            A
                                        </span>
                                    </div>
                                    <div className="flex w-5 h-5 flex-col justify-center items-center gap-[5px] rounded-sm bg-[#0A85FF]">
                                        <span className="text-white text-center text-[10px] font-bold tracking-[0.3px]">
                                            B
                                        </span>
                                    </div>
                                    <div className="flex w-5 h-5 flex-col justify-center items-center gap-[5px] rounded-sm bg-[#9747FF]">
                                        <span className="text-white text-center text-[10px] font-bold tracking-[0.3px]">
                                            C
                                        </span>
                                    </div>
                                    <div className="flex w-5 h-5 flex-col justify-center items-center gap-[5px] rounded-sm bg-[#454545]">
                                        <span className="text-white text-center text-[10px] font-bold tracking-[0.3px]">
                                            P
                                        </span>
                                    </div>
                                </div>
                                <p className="font-openSans text-[#555] text-[10px] font-bold tracking-[0.3px] ">
                                    <span className="font-extrabold text-center text-[#FF2121]">
                                        *
                                    </span>{' '}
                                    Limited opportunities. See individual
                                    guidelines
                                </p>
                            </div>
                        </div>

                        {/* PROPERTIES */}
                        {content?.brands?.map(
                            ({
                                logo,
                                name,
                                properties,
                                franchises,
                                page_uri,
                            }) => {
                                return name === 'orphans' ? (
                                    <div
                                        key={name}
                                        className="flex p-[15px] items-start gap-5 self-stretch rounded-[5px] border border-[#999] flex-wrap"
                                    >
                                        {' '}
                                        <div className="w-[100px]">
                                            {' '}
                                            <img
                                                src={logo.source}
                                                alt={logo.alt}
                                                className="w-full h-[75px] object-contain "
                                            />
                                        </div>
                                        {/* GRID COLUMN */}
                                        <div>
                                            {franchises?.map(
                                                ({
                                                    name,
                                                    priority,
                                                    page_uri,
                                                }) => {
                                                    return (
                                                        <div className="flex flex-col items-start flex-grow flex-shrink-0 gap-5 basis-0">
                                                            {/* GRID GROUP */}
                                                            <div className="flex flex-col items-start self-stretch">
                                                                {/* GRID ITEM */}
                                                                <div className="flex items-center gap-[5px] self-stretch">
                                                                    <div
                                                                        className={`${
                                                                            priority ===
                                                                            'P'
                                                                                ? 'bg-[#454545]'
                                                                                : priority ===
                                                                                  'A'
                                                                                ? 'bg-[#EC3D3D]'
                                                                                : priority ===
                                                                                  'B'
                                                                                ? 'bg-[#0A85FF]'
                                                                                : priority ===
                                                                                  'C'
                                                                                ? 'bg-[#9747FF]'
                                                                                : 'bg-yellow-500'
                                                                        } flex w-3 h-3 flex-col justify-center items-center gap-[5px] rounded-sm `}
                                                                    >
                                                                        <span className="font-openSans text-white text-center text-[6px] font-bold tracking-[0.18px]">
                                                                            {
                                                                                priority
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <Link
                                                                        to={
                                                                            page_uri
                                                                        }
                                                                    >
                                                                        <p className="flex-grow flex-shrink-0 basis-0 text-[#555] font-inter text-sm hover:opacity-80">
                                                                            {
                                                                                name
                                                                            }
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                            {properties?.map(
                                                ({
                                                    name,
                                                    priority,
                                                    page_uri,
                                                }) => {
                                                    return (
                                                        <div className="flex flex-col items-start flex-grow flex-shrink-0 gap-5 basis-0">
                                                            {/* GRID GROUP */}
                                                            <div className="flex flex-col items-start self-stretch">
                                                                {/* GRID ITEM */}
                                                                <div className="flex items-center gap-[5px] self-stretch">
                                                                    <div
                                                                        className={`${
                                                                            priority ===
                                                                            'P'
                                                                                ? 'bg-[#454545]'
                                                                                : priority ===
                                                                                  'A'
                                                                                ? 'bg-[#EC3D3D]'
                                                                                : priority ===
                                                                                  'B'
                                                                                ? 'bg-[#0A85FF]'
                                                                                : priority ===
                                                                                  'C'
                                                                                ? 'bg-[#9747FF]'
                                                                                : 'bg-yellow-500'
                                                                        } flex w-3 h-3 flex-col justify-center items-center gap-[5px] rounded-sm `}
                                                                    >
                                                                        <span className="font-openSans text-white text-center text-[6px] font-bold tracking-[0.18px]">
                                                                            {
                                                                                priority
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <Link
                                                                        to={
                                                                            page_uri
                                                                        }
                                                                    >
                                                                        <p className="flex-grow flex-shrink-0 basis-0 text-[#555] font-inter text-sm hover:opacity-80">
                                                                            {
                                                                                name
                                                                            }
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={name}
                                        className="flex p-[15px] items-start gap-5 self-stretch rounded-[5px] border border-[#999]"
                                    >
                                        <div className="w-[100px]">
                                            {' '}
                                            <Link to={page_uri}>
                                                <img
                                                    src={logo.source}
                                                    alt={name}
                                                    className={`${
                                                        logo.source ===
                                                        'https://seeker-misc-public.s3.us-west-2.amazonaws.com/default_featuredImage.png'
                                                            ? ''
                                                            : 'invert'
                                                    } w-full h-[75px] hover:opacity-80 object-contain`}
                                                />
                                            </Link>
                                        </div>
                                        {/* GRID COLUMN */}
                                        <div className="masonry">
                                            {franchises?.map(
                                                ({
                                                    name: franchiseName,
                                                    properties,
                                                    page_uri,
                                                }) => {
                                                    return (
                                                        <div className="brick">
                                                            {/* GRID GROUP */}
                                                            <Link to={page_uri}>
                                                                <h2 className="text-[#555] font-openSans text-lg font-extrabold uppercase hover:opacity-80">
                                                                    {
                                                                        franchiseName
                                                                    }
                                                                </h2>
                                                            </Link>
                                                            {sortedProperties(
                                                                properties
                                                            ).map(
                                                                ({
                                                                    name: propertyName,
                                                                    priority,
                                                                    page_uri,
                                                                }) => (
                                                                    <div className="flex gap-[5px] self-stretch">
                                                                        <div
                                                                            className={`${
                                                                                priority ===
                                                                                'P'
                                                                                    ? 'bg-[#454545]'
                                                                                    : priority ===
                                                                                      'A'
                                                                                    ? 'bg-[#EC3D3D]'
                                                                                    : priority ===
                                                                                      'B'
                                                                                    ? 'bg-[#0A85FF]'
                                                                                    : priority ===
                                                                                      'C'
                                                                                    ? 'bg-[#9747FF]'
                                                                                    : 'bg-yellow-500'
                                                                            } flex w-3 h-3 flex-col justify-center items-center gap-[5px] rounded-sm  mt-1`}
                                                                        >
                                                                            <span className="font-openSans text-white text-center text-[6px] font-bold tracking-[0.18px] flex items-center">
                                                                                {
                                                                                    priority
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <Link
                                                                            to={
                                                                                page_uri
                                                                            }
                                                                        >
                                                                            <p className=" text-[#555] font-inter text-sm hover:opacity-80">
                                                                                {
                                                                                    propertyName
                                                                                }
                                                                            </p>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ActiveGridPage
