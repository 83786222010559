import React, { useEffect, useState } from 'react'
import './BrandFranchiseHero.scss'
import SectionEditButton from '../SectionEditButton/SectionEditButton'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

const BrandFranchiseHero = ({ content, contentIsLoading }) => {
    const transformPercentToDecimal = (percent) => {
        if (percent === 0) return 0
        return percent / 100
    }
    let stylesheet = "";
    if(content.data.styles?.stylesheet) {
        stylesheet = { __html: content.data.styles.stylesheet };
    }

    return (
        <section
            style={{ '--color': content.data?.styles?.text_color }}
            className="overflow-hidden relative h-[600px] mx-auto brand-hero before:block before:absolute before:inset-0 flex flex-col items-start w-full text-[var(--color)]"
        >
            {content.data?.styles?.stylesheet && (
                <div dangerouslySetInnerHTML={stylesheet} />
            )}
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url(${content.data?.introduction?.image?.source})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: `${
                        content.data?.introduction?.background_blurred &&
                        'blur(50px)'
                    }`,
                }}
            />
            <div
                className="absolute inset-0 bg-[color:var(--bgcolor)]"
                style={{
                    opacity: `${transformPercentToDecimal(
                        content.structured?.overlay_transparency_percent
                    )}`,
                    '--bgcolor': content.data?.introduction?.background_color,
                }}
            />

            <SectionEditButton
                hash="#section-introduction"
                id={content.ID}
                className="mr-10 top-20"
            />

            <Breadcrumbs className="absolute top-2.5 pl-6" content={content} />
            <div className="relative w-full h-full">
                {!contentIsLoading && (
                    <div className="flex flex-col items-center h-full mx-auto max-w-[1440px] md:flex-row md:items-stretch gap-2.5  ">
                        {content.post_type !== 'brands' && (
                            <div className="px-10 py-12.5 basis-full flex">
                                {content.post_type === 'property' && content.data?.blur_hero_image && (
                                    <img
                                        className="object-contain max-w-[348px] max-h-[520px] mx-auto"
                                        src={
                                            content.data?.featuredImage?.source
                                        }
                                        alt={content.data?.featuredImage.alt}
                                    />
                                )}
                            </div>
                        )}
                        <div className="h-full pb-6 basis-full">
                            <figure className="flex flex-col px-10 pt-12.5 gap-10 hero-branded-font-color h-full items-center justify-center ">
                                {content.data?.logo_image?.source && (
                                    <div className="max-h-[220px] max-w-[195px] mx-auto drop-shadow-md">
                                        <img
                                            src={
                                                content.data?.logo_image?.source
                                            }
                                            alt={content.data?.log_image?.alt}
                                            className="object-contain w-full h-full drop-shadow-md"
                                        />
                                    </div>
                                )}
                                {content?.data?.introduction?.title && (
                                    <h1
                                        className={`${
                                            content?.post_type === 'property' &&
                                            'text-[38px]'
                                        } ${
                                            content.data.hide_title_text &&
                                            'visually-hidden'
                                        } font-extrabold text-center uppercase font-openSans drop-shadow-md text-shadow`}
                                    >
                                        {content.data.introduction.title}
                                    </h1>
                                )}

                                {content.post_type === 'property' && (
                                    <div
                                        className={`${
                                            content?.post_type === 'property'
                                                ? 'text-2xl '
                                                : 'wp-content'
                                        } text-overflow-scroll tracking-normal wp-brand-hero font-wbs text-shadow leading-normal`}
                                    >
                                        {content?.data?.introduction?.text}
                                    </div>
                                )}
                            </figure>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default BrandFranchiseHero
