import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import './NavLinks.css'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import './NavLinks.css'
import { Link } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { loadableFranchiseHomeAtom, loadableCollectionsAtom } from 'utils/atoms'
import { NavLinkOption } from './NavLinkOption/NavLinkOption'
import { sortAlphabetically } from 'utils/utils'

const NavLinks = () => {
    // const { brand, franchise, property } = useParams()
    const { data } = useAtomValue(loadableFranchiseHomeAtom)

    const { data: collections } = useAtomValue(loadableCollectionsAtom)

    // if (!collections || collections.statusCode === 404) return
    if (!collections) return
    const showCollectionsDropdown = collections?.some(
        (collections) => collections.organizing_principle
    )
    const alphabeticalOrderedOrganizingPrincples =
        sortAlphabetically(collections)

    const alphabeticallyOrderedBrands = sortAlphabetically(
        data?.structured?.brands
    )

    const resources = {
        name: 'Resources',
        links: [
            // {
            //     name: 'Calendar',
            //     link: franchise
            //         ? `${brand}/${franchise}/calendar`
            //         : brand
            //         ? `${brand}/calendar`
            //         : '/tools/calendar',
            // },
            {
                name: 'Star Labs',
                slug: 'https://portal.starlabs.warnerbros.com/#!/home',
                id: 1,
                target: '_blank',
            },
        ],
    }

    return (
        <Popover.Group className="hidden lg:flex w-[540px] self-stretch">
            <Popover className="relative flex h-full basis-full">
                <Popover.Button className="flex items-center justify-center text-lg font-bold leading-6 text-white group/brands focus:text-accentblue focus:bg-btnbggrey focus:outline-none gap-x-1 basis-full hover:bg-btnbggrey hover:text-accentblue ">
                    <span className="pb-.5 group-hover/brands:border-b border-accentblue group-focus/brands:border-b">
                        Brands
                    </span>
                    <ChevronDownIcon
                        className="flex-none h-6 font-extrabold group-hover/brands:text-accentblue"
                        aria-hidden="true"
                    />
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute z-30 max-w-[180px] w-screen overflow-visible overflow-y-visible bg-white shadow-lg top-full py-3">
                        {/* <div className="triangle-up" /> */}

                        {alphabeticallyOrderedBrands?.map(
                            ({ id, hidden, name, slug }) => {
                                if (hidden) return null

                                return (
                                    <div key={id}>
                                        <NavLinkOption
                                            name={name}
                                            slug={slug}
                                            id={id}
                                        />
                                    </div>
                                )
                            }
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>

            <Popover className="relative flex basis-full ">
                <Popover.Button className="flex items-center justify-center text-lg font-bold leading-6 text-white group/franchises font-wbs gap-x-1 basis-full hover:bg-btnbggrey hover:text-accentblue focus:text-accentblue focus:outline-none focus:bg-btnbggrey">
                    <span className="pb-.5 group-focus/franchises:border-b group-hover/franchises:border-b border-accentblue">
                        Collections
                    </span>
                    <ChevronDownIcon
                        className="flex-none w-6 h-6 font-extrabold group-hover/franchises:text-accentblue"
                        aria-hidden="true"
                    />
                </Popover.Button>
                {showCollectionsDropdown && (
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-30 w-[180px] py-3 bg-white shadow-lg top-full">
                            {/* <div className="triangle-up" /> */}

                            {alphabeticalOrderedOrganizingPrincples?.map(
                                ({ id, name, slug, organizing_principle }) =>
                                    organizing_principle && (
                                        <NavLinkOption
                                            name={name}
                                            slug={slug}
                                            id={id}
                                            key={id}
                                        />
                                    )
                            )}
                        </Popover.Panel>
                    </Transition>
                )}
            </Popover>
            <Popover className="relative flex basis-full">
                <Popover.Button className="flex items-center justify-center text-lg font-bold leading-6 text-white group/resources gap-x-1 basis-full hover:bg-btnbggrey hover:text-accentblue focus:text-accentblue focus:outline-none focus:bg-btnbggrey ">
                    <span className="pb-.5 group-focus/resources:border-b group-hover/resources:border-b border-accentblue">
                        Resources
                    </span>
                    <ChevronDownIcon
                        className="flex-none w-6 h-6 group-hover/resources:text-accentblue"
                        aria-hidden="true"
                    />
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute z-30 w-[180px] py-3 bg-white shadow-lg top-full">
                        {/* <div className="triangle-up" /> */}
                        {resources.links.map(({ name, slug, id, target }) => (
                            <NavLinkOption
                                name={name}
                                slug={slug}
                                key={id}
                                target={target}
                            />
                        ))}
                        <div className="relative flex px-3.5 py-2 leading-6 group gap-x-6 text-seekerblue hover:bg-seekerblue hover:text-white">
                            <div className="flex-auto">
                                <Link
                                    to={'active-grid'}
                                    className="block text-base font-bold uppercase font-wbSans"
                                >
                                    Active Grid
                                </Link>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </Popover.Group>
    )
}

export default NavLinks
