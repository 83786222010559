const DefaultTextOverlay = ({ fontSize, name }) => {
    return (
        <div className="absolute bottom-0 flex justify-center w-full mx-auto text-center text-white align-center ">
            <span
                className={`text-[${fontSize}px] font-openSans text-center tracking-[2px] font-bold pb-2 absolute bottom-0 mx-auto `}
            >
                {name}
            </span>
        </div>
    )
}

export default DefaultTextOverlay
