import React from 'react'
import { useParams } from 'react-router-dom'

const RestrictedContent = () => {
    const { page } = useParams()

    return (
        <div className="flex flex-grow h-full text-center align-items justify-items justify-content">
            <div className="flex self-stretch flex-grow w-full min-h-full mx-automax-w-8xl align-items">
                <div className="flex flex-col justify-center flex-grow h-full align-items ">
                    <h1
                        className={`mb-0 text-4xl font-extrabold tracking-wider text-center uppercase sm:text-2xl md:text-3xl font-openSans ${
                            page ? 'text-[#555]' : 'text-white'
                        }`}
                    >
                        Restricted content
                    </h1>
                    <p
                        className={`font-inter sm:text-lg text-xl py-12 uppercase text-center mx-auto text-[#555] w-full ${
                            page ? 'text-[#555]' : 'text-white'
                        }`}
                    >
                        Sorry, your contract does not allow access to this
                        content. If you believe this is an error, please contact
                        us for support.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RestrictedContent
