import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PrevArrow, NextArrow } from './CustomArrows/CustomArrows'
import { classNames } from 'utils/utils'
import './FeaturedSlider.css'
import DefaultTextOverlay from 'pages/franchise/components/DefaultTextOverlay/DefaultTextOverlay'

const FeaturedSlider = (props) => {
    const [isSwipe, setIsSwipe] = useState(false)
    const sliderRef = useRef(null)
    const navigate = useNavigate()
    const { className: c, featured } = props
    const CARDS_TO_SHOW = 5
    if (!featured) return
    const settings = {
        infinite: true,
        speed: 300,
        slidesToShow:
            featured.length > CARDS_TO_SHOW ? CARDS_TO_SHOW : featured.length,
        slidesToScroll:
            featured.length > CARDS_TO_SHOW ? CARDS_TO_SHOW : featured.length,
        initialSlide: 0,
        onSwipe: () => {
            setIsSwipe(true)
        },
        afterChange: () => {
            setIsSwipe(false)
        },
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: Math.min(featured.length, 4),
                    slidesToScroll: Math.min(featured.length, 4),
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(featured.length, 3),
                    slidesToScroll: Math.min(featured.length, 3),
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: Math.min(featured.length, 2),
                    slidesToScroll: Math.min(featured.length, 2),
                    infinite: true,
                },
            },
            // {
            //     breakpoint: 480,
            //     settings: {
            //         slidesToShow: Math.min(featured.length, 2),
            //         slidesToScroll: Math.min(featured.length, 2),
            //     },
            // },
        ],
    }

    const handleImageClick = (link) => {
        if (!isSwipe) {
            navigate(link)
        }
    }

    return (
        <div className={classNames('featured-slider w-full', c)}>
            <h2 className="text-2xl font-bold text-white uppercase mb-2.5 py-2.5 leading-8 font-openSans tracking-wbwidest">
                Featured
            </h2>
            <div ref={sliderRef} className="mx-auto carousel">
                <Slider
                    {...settings}
                    className={`${
                        featured.length > CARDS_TO_SHOW && 'display-container'
                    }`}
                >
                    {featured.map(
                        ({ image: { source, alt }, slug, name, id }) => (
                            <div
                                key={id}
                                className="relative h-96 slider-item !w-64"
                                onClick={() => handleImageClick(slug)}
                            >
                                <img
                                    src={source}
                                    alt={alt}
                                    className="object-cover w-full h-full hover:opacity-90 slider-image"
                                />
                                {source?.endsWith(
                                    'default_featuredImage.png'
                                ) && (
                                    <DefaultTextOverlay
                                        fontSize={12}
                                        name={name}
                                    />
                                )}
                            </div>
                        )
                    )}
                </Slider>
            </div>
        </div>
    )
}

export default FeaturedSlider
