import { useState } from 'react'
import BtnContainer from './BtnContainer/BtnContainer'
import TabInfo from './TabInfo/TabInfo'
import { useParams } from 'react-router-dom'

const BrandsFranchisesResourcesTabs = ({ content }) => {
    const { franchise, property } = useParams()
    let tabButtonsList = ['overview', 'guidelines', 'resources']

    const [currentTab, setCurrentTab] = useState('overview')
    // const [isLoading, setIsLoading] = useState(true)
    if (!content) return null

    // if (isLoading) {
    //     return <div>Loading</div>
    // }
    return (
        <section className="bg-white/5 flex px-2 sm:px-14 md:px-[60px] pt-2.5 pb-[60px] flex-col items-center gap-[60px]">
            <BtnContainer
                content={content}
                tabButtonsList={tabButtonsList}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            <TabInfo
                tabButtonsList={tabButtonsList}
                currentTab={currentTab}
                content={content}
            />
        </section>
    )
}

export default BrandsFranchisesResourcesTabs
