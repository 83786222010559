import { Link, useLocation } from 'react-router-dom'
import './Collections.css'
import { classNames } from 'utils/utils'

const Collections = (props) => {
    const { className: c, titlePadding, collections } = props
    const location = useLocation()
    const isHomePage = location.pathname === '/franchise'
    if (!collections) return
    return (
        <div className={classNames('gap-y-2.5 w-full', c)}>
            <h2
                style={{
                    paddingRight: titlePadding,
                    paddingLeft: titlePadding,
                }}
                className="text-2xl font-bold text-white uppercase mb-2.5 py-2.5 leading-8 font-openSans tracking-wbwidest relative"
            >
                Collections
            </h2>
            <div className="flex flex-wrap justify-center w-full gap-5">
                {collections.map((collection, index) => {
                    const { featured_image, hero_image, name, slug, id } =
                        collection

                    return (
                        !collection.hidden && (
                            <Link to={slug} key={index}>
                                <div
                                    className={`${
                                        isHomePage
                                            ? 'collection-container-homepage'
                                            : 'collection-container'
                                    } flex flex-col items-center px-2.5 py-2.5 gap-2.5 rounded-[10px] relative group`}
                                >
                                    <div className="w-[290px] h-[170px] rounded-[5px] image-container relative">
                                        <img
                                            src={featured_image.source}
                                            alt={featured_image.alt}
                                            className="object-cover w-full h-full rounded-[5px] group-hover:opacity-80"
                                        ></img>
                                    </div>
                                    <p className="font-bold text-center text-white uppercase font-openSans tracking-wbwidest">
                                        {name}
                                    </p>
                                </div>
                            </Link>
                        )
                    )
                })}
            </div>
        </div>
    )
}

export default Collections
