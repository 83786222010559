import React from 'react'
import { Link } from 'react-router-dom'
import { sortAlphabetically } from 'utils/utils'
const FooterLinks = ({ brands }) => {
    const visibleBrands = brands?.filter((brand) => !brand.hidden)
    const sortedVisibleBrands = sortAlphabetically(visibleBrands)
    return (
        <>
            <section className="mx-auto bg-[#080918]">
                <div className=" px-6 pt-10 pb-9 md:px-12 lg:px-[100px] mx-auto">
                    <div className="xl:grid xl:grid-cols-2 ">
                        <div className="md:divide-x-[1px] md:divide-[#757575] grid grid-cols-2 mt-16 xl:col-span-2 xl:mt-0 place-items-start md:place-items-stretch">
                            <div className="md:divide-x-[1px] md:divide-[#757575] md:grid md:grid-cols-2 font-inter">
                                <div className="px-10 pb-6 md:pb-0">
                                    <h3 className="text-lg font-bold leading-6 text-white font-inter">
                                        Explore Our Brands
                                    </h3>
                                    <ul role="list" className="mt-5 leading-6">
                                        {sortedVisibleBrands?.map(
                                            ({ id, name, slug }) => (
                                                <li key={id} className="">
                                                    <Link
                                                        to={slug}
                                                        className="text-sm text-blue hover:text-white"
                                                    >
                                                        {name}
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                                <div className="px-10">
                                    <h3 className="text-lg font-bold text-white font-inter">
                                        News
                                    </h3>
                                    <ul role="list" className="mt-5 leading-6">
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="md:divide-x-[1px] md:divide-[#757575] md:grid md:grid-cols-2 font-inter ">
                                <div className="px-10 pb-6 md:pb-0">
                                    <h3 className="text-lg font-bold text-white font-inter">
                                        Resources
                                    </h3>
                                    <ul role="list" className="mt-5 leading-6">
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="px-10">
                                    <h3 className="text-lg font-bold text-white font-inter">
                                        Favorites
                                    </h3>
                                    <ul role="list" className="mt-5 leading-6 ">
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                Placeholder Link
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FooterLinks
