export const NavLinkOption = ({ slug, name, target }) => {
    return (
        <div className="relative flex px-3.5 py-2 leading-6 group gap-x-6 text-seekerblue hover:bg-seekerblue hover:text-white">
            <div className="flex-auto">
                <a
                    href={slug}
                    className="block text-base font-bold uppercase font-wbSans"
                    target={target}
                >
                    {name}
                </a>
            </div>
        </div>
    )
}
