import './franchise-hub.scss'
import { Link } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai'
import { loadableFranchiseHomeAtom, loadableCollectionsAtom } from 'utils/atoms'
import FooterLinks from './components/FooterLinks/FooterLinks'
import FeaturedSlider from 'components/FeaturedSlider/FeaturedSlider'
import Collections from 'components/Collections/Collections'
import DefaultTextOverlay from './components/DefaultTextOverlay/DefaultTextOverlay'
import { sortAlphabetically } from 'utils/utils'
// import CollectionPage from 'pages/CollectionsPage/CollectionPage'
// import useApi from 'hooks/useApi'

export default function FranchiseHub() {
    const [franchiseHomeAtom] = useAtom(loadableFranchiseHomeAtom)
    const { data: collections } = useAtomValue(loadableCollectionsAtom)

    if (!collections) return
    const alphabeticalOrderedOrganizingPrincples = sortAlphabetically(
        collections
    ).filter((collection) => collection.organizing_principle)

    // if (franchiseHomeAtom.state === 'loading') {
    //     return <div>Loading...</div>
    // }

    // const organizing_principles = content?.structured?.collections?.filter(
    //     (collection) => organizing_principle
    // )
    // return <CollectionPage />
    return (
        <main className="bg-[#080918] z-0">
            {franchiseHomeAtom.data?.styles?.stylesheet && (franchiseHomeAtom.data.styles.stylesheet)}
            {/* Hero section */}
            <section className="relative mx-auto overflow-hidden isolate max-w-8xl flex flex-col">
                {franchiseHomeAtom.data?.data?.introduction?.image?.source && (
                    <img
                        src={
                            franchiseHomeAtom.data?.structured
                                ?.introduction_background
                        }
                        alt={
                            franchiseHomeAtom.data?.data?.introduction?.image
                                ?.alt
                        }
                        className="absolute inset-0 object-cover -z-10"
                    />
                )}
                <div className="pt-[405px]"></div>
                <div className="px-4 mx-auto max-w-8xl lg:px-2.5 pt-25 pb-12.5">
                    {/* Logo cloud */}

                    <div className="flex flex-col gap-y-[100px] max-w-xl sm:max-w-2xl lg:max-w-4xl xl:max-w-6xl">
                        <div className="flex sm:flex-row flex-col flex-wrap justify-center gap-[100px] mx-auto ">
                            {franchiseHomeAtom.data?.data?.children?.map(
                                ({ featured_image, id, slug, title }) => {
                                    return (
                                        <Link
                                            to={slug}
                                            className="hover:opacity-80"
                                            key={id}
                                        >
                                            <div className="h-[75px] relative">
                                                <img
                                                    className="object-scale-down w-full h-full"
                                                    src={featured_image.source}
                                                    alt={featured_image.alt}
                                                ></img>
                                                {featured_image.source?.endsWith(
                                                    'default_featuredImage.png'
                                                ) && (
                                                    <DefaultTextOverlay
                                                        fontSize={10}
                                                        name={title}
                                                    />
                                                )}
                                            </div>
                                        </Link>
                                    )
                                }
                            )}
                        </div>

                        {/* ORGANIZING PRINCIPLES */}
                        {alphabeticalOrderedOrganizingPrincples.length > 0 && (
                            <div className="flex sm:flex-row flex-col text-center flex-wrap justify-center gap-x-[100px] gap-y-[110px]">
                                {alphabeticalOrderedOrganizingPrincples?.map(
                                    (collection) => {
                                        return (
                                            collection.organizing_principle && (
                                                <Link
                                                    to={collection.slug}
                                                    className="hover:opacity-80"
                                                    key={collection.id}
                                                >
                                                    <div className="text-2xl font-bold leading-8 text-white uppercase font-openSans tracking-wbwidest">
                                                        <p>{collection.name}</p>
                                                    </div>
                                                </Link>
                                            )
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <div className="flex flex-col gap-y-2.5 relative ">
                {franchiseHomeAtom.data?.structured?.show_featured &&
                    franchiseHomeAtom.data?.data?.featured?.length > 0 && (
                        <div className="px-12.5 py-[30px]">
                            <FeaturedSlider
                                featured={
                                    franchiseHomeAtom.data?.data?.featured
                                }
                            />
                        </div>
                    )}
                {franchiseHomeAtom.data?.structured?.show_collections &&
                    franchiseHomeAtom.data?.structured?.collections.length >
                        0 && (
                        <div className="pt-[30px] pb-20 px-12.5">
                            <Collections
                                collections={
                                    franchiseHomeAtom.data?.data?.collections
                                }
                            />
                        </div>
                    )}
                <div className="absolute inset-x-0 top-0 bottom-0 -z-10 gradient-container"></div>
            </div>

            {/* Welcome section */}
            <section className="bg-white max-w-[1440px] md:pr-10 py-10 px-8 md:px-0 md:py-0 mx-auto">
                <div className="flex gap-x-10">
                    <div className="flex-none hidden basis-1/2 max-w-[680px] md:block gap-y-10">
                        <div className="relative h-[624px] mx-0 aspect-auto">
                            <img
                                className="absolute inset-0 object-cover w-full h-full"
                                src={
                                    franchiseHomeAtom.data?.data
                                        ?.welcome_section?.image.source
                                }
                                alt={
                                    franchiseHomeAtom.data?.data
                                        ?.welcome_section?.image.alt
                                }
                            ></img>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center w-full mx-auto text-center md:text-left">
                        <div>
                            <h1 className="text-3xl font-extrabold uppercase font-openSans text-midblue">
                                {
                                    franchiseHomeAtom?.data?.data
                                        ?.welcome_section?.header
                                }
                            </h1>

                            <div className="pt-10 text-xl intro-text">
                                {
                                    franchiseHomeAtom?.data?.data
                                                    ?.welcome_section
                                                    ?.text
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterLinks brands={franchiseHomeAtom.data?.structured?.brands} />
        </main>
    )
}
