import { useState, useRef, memo } from 'react'
import './FranchiseTopbar.scss'
import { Link } from 'react-router-dom'
import FranchiseNav from '../FranchiseNav/FranchiseNav'
import { matchesAtom, searchTermAtom, isNavHoveredAtom } from 'utils/atoms'
import WBLogo from '__shared/images/icons/wb-logo.png'
import AnimatedSearch from '../AnimatedSearch/AnimatedSearch'
import { Dialog } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import { useSetAtom } from 'jotai'
import NavLinks from './NavLinks/NavLinks'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons'
export default memo(function FranchiseTopbar({ calendar }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isInternal, setIsInternal] = useState(true)
    const setMatches = useSetAtom(matchesAtom)
    const setSearchTerm = useSetAtom(searchTermAtom)
    const searchInputRef = useRef(null)
    const setIsNavHovered = useSetAtom(isNavHoveredAtom)

    const onNavHover = (e) => {
        setIsNavHovered(true)
    }
    const onNavLeave = () => {
        setIsNavHovered(false)
        setMatches([])
        setSearchTerm('')
        searchInputRef.current.blur()
    }
    return (
        <header className="w-full franchise-topbarv2 group">
            <nav
                className="relative px-6 mx-auto max-w-8xl nav"
                aria-label="Global"
                onMouseOver={onNavHover}
                onMouseLeave={onNavLeave}
            >
                <div className="flex items-center justify-between h-[60px] w-full max-w-8xl nav-center">
                    <div className="flex items-center h-full text-sm font-semibold leading-6 text-black">
                        <div className="flex items-center lg:flex-1 w-[280px]">
                            <Link
                                to="/franchise"
                                className="flex items-center gap-x-2.5 hover:opacity-[.85]"
                            >
                                <span className="sr-only">Franchise Hub</span>
                                <img
                                    className="w-auto h-[30px] franchise-hub-logo"
                                    src={WBLogo}
                                    alt="Warner Brothers Discovery Logo"
                                />
                                <p className="font-extrabold tracking-wider text-white uppercase text-1xl font-inter">
                                    Franchise Hub
                                </p>
                            </Link>
                        </div>
                        <NavLinks />
                    </div>

                    {/* Hamburger mobile menu */}
                    <div className="flex sm:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex rounded-md p-2.5 text-white "
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon
                                className="flex w-10 h-10 hamburger-nav"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div className="items-center self-stretch hidden sm:flex sm:justify-end franchiseNav-buttons gap-x-[25px]">
                        <AnimatedSearch ref={searchInputRef} />
                        <ProfileDropdown />

                        {/* <div
                            className=""
                            onClick={() => setIsInternal(!isInternal)}
                        >
                            {isInternal ? (
                                <div className="bg-[#E17055] h-8 flex items-center justify-end rounded-[5px] gap-x-[5px] px-[5px]">
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="text-xl leading-8 tracking-wider text-white "
                                    />
                                    <span className="text-xl leading-8 text-white uppercase font-wbSans">
                                        Internal
                                    </span>
                                </div>
                            ) : (
                                <div className="h-8 flex items-center justify-end rounded-[5px] gap-x-[5px] px-[5px] bg-neutral-400">
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="text-xl leading-8 tracking-wider text-white "
                                    />
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
            </nav>
            {/* <ProfilePanel open={open} setOpen={setOpen} /> */}
            {/* Modal panel for mobile nav */}
            <Dialog
                as="div"
                className="sm:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black">
                    <FranchiseNav
                        setMobileMenuOpen={setMobileMenuOpen}
                        calendar={calendar}
                    />
                </Dialog.Panel>
            </Dialog>
        </header>
    )
})
