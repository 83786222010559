import FranchisesCardList from './FranchisesCardList/FranchisesCardList'

const FranchisesCards = ({ brand, franchises }) => {
    return (
        <section className="pt-5 pb-[45px] bg-white/5">
            <div className="flex flex-col px-5 gap-y-[45px]">
                <p className="text-2xl font-bold leading-8 text-white uppercase font-openSans tracking-wbwidest">
                    {brand} Franchises
                </p>
                <FranchisesCardList franchises={franchises} />
            </div>
        </section>
    )
}

export default FranchisesCards
