import React from 'react'
import RenderHTMLContent from 'pages/brand-franchisePage/components/RenderHTMLContent/RenderHTMLContent'
import Contacts from './Contacts/Contacts'
import PageAndFileLinks from './PageAndFileLinks/PageAndFileLinks'
import PropertiesContent from './PropertiesContent/PropertiesContent'

const TabInfo = ({ content, currentTab }) => {
    const keysInOrder = ['brand', 'franchise', 'property']
    return (
        <div className="flex flex-col items-start self-stretch gap-[30px] tab-content">
            {/* DEACTIVATED BANNER */}
            {content?.data?.show_deactivated_banner && (
                <div className="flex py-[30px] flex-col flex-start gap-[5px] self-stretch bg-[#E74C3C1A]/[.1] rounded-[10px] border border-[#E74C3C]">
                    <h2 className="self-stretch text-3xl text-[#FC7365] font-extrabold text-center uppercase font-openSans">
                        Deactivated Property
                    </h2>
                    <p className="text-[#FC7365] text-center font-inter text-xl">
                        This property is currently unavailable for licensing
                    </p>
                </div>
            )}

            <div className="flex flex-col self-stretch">
                <h2 className="flex flex-col self-stretch justify-center text-3xl font-extrabold text-white uppercase font-openSans h-14">
                    {currentTab}
                </h2>
                <div className="text-xl text-justify text-white wp-resources font-inter">
                    <RenderHTMLContent
                        htmlContent={content?.structured?.[currentTab]}
                    />
                </div>
            </div>

            {currentTab === 'guidelines' && content.post_type !== 'brands' ? (
                <div className="flex gap-[60px] flex-col self-stretch">
                    {keysInOrder.map((key) => {
                        const value =
                            content?.structured?.parent_guidelines[key]
                        if (value !== null && typeof value === 'object') {
                            return (
                                value.links.length > 0 && (
                                    <div className="flex flex-col gap-y-5">
                                        <h3 className="text-xl font-extrabold text-white uppercase font-openSans">
                                            {value.name} {key}
                                        </h3>

                                        <div className="flex items-start self-stretch gap-y-5">
                                            <div className="flex flex-col items-start flex-grow">
                                                {value.links.map(
                                                    ({
                                                        added_at,
                                                        file_type,
                                                        file_url,
                                                        hidden,
                                                        id,
                                                        name,
                                                        slug,
                                                        type,
                                                    }) => {
                                                        let fileDate = new Date(added_at);
                                                        const transformedDate = fileDate.getDate()+"/"+fileDate.getMonth()+"/"+fileDate.getFullYear()
                                                        return (
                                                            !hidden && (
                                                                <PageAndFileLinks
                                                                    date={
                                                                        transformedDate
                                                                    }
                                                                    file_type={
                                                                        file_type
                                                                    }
                                                                    file_url={
                                                                        file_url
                                                                    }
                                                                    id={id}
                                                                    name={name}
                                                                    slug={slug}
                                                                    type={type}
                                                                    key={id}
                                                                />
                                                            )
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                        return null
                    })}
                </div>
            ) : (
                <div className="flex pb-[30px] items-start self-stretch ">
                    <div className="flex flex-col items-start flex-grow">
                        {content.structured &&
                            content.structured[`${currentTab}_content`].map(
                                ({
                                    added_at,
                                    file_type,
                                    file_url,
                                    hidden,
                                    id,
                                    name,
                                    slug,
                                    type,
                                }) => {
                                    let fileDate = new Date(added_at);
                                    const transformedDate = fileDate.getDate()+"/"+fileDate.getMonth()+"/"+fileDate.getFullYear()
                                    return (
                                        !hidden && (
                                            <PageAndFileLinks
                                                date={transformedDate}
                                                file_type={file_type}
                                                file_url={file_url}
                                                id={id}
                                                name={name}
                                                slug={slug}
                                                type={type}
                                                key={id}
                                            />
                                        )
                                    )
                                }
                            )}
                    </div>
                </div>
            )}

            {content?.structured?.show_contacts &&
                content?.structured?.key_contacts.length &&
                currentTab === 'overview' && (
                    <Contacts contacts={content.structured.key_contacts} />
                )}
        </div>
    )
}

export default TabInfo
