import Contact from './Contact/Contact'

const Contacts = ({ contacts }) => {
    if (!contacts) return null
    return (
        <div className="flex flex-col py-[30px] px-10 items-start gap-5 self-stretch bg-white/[0.05]">
            <h3 className="self-stretch text-lg font-extrabold text-white uppercase font-openSans">
                Key Contacts
            </h3>
            <div className="flex flex-col items-start self-stretch gap-5 ">
                <div className="flex flex-wrap items-start content-center self-stretch gap-y-5">
                    {contacts?.map((contact, index) => (
                        <Contact contact={contact} index={index} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Contacts
