import React from 'react'
import { getFileExtension } from 'utils/utils'
import VideojsPlayer from '../Videojs/VideojsPlayer'
// import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// Your render function
const PreviewLink = ({ link }) => {
    const fileExtension = getFileExtension(link)
    const playerRef = React.useRef(null)
    const filename = link.substring(link.lastIndexOf('/') + 1)
    if (
        fileExtension === 'png' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg'
    ) {
        return (
            <>
                <img src={link} width="auto" className="pb-4 mx-auto" />
            </>
        )
    }
    if (fileExtension === 'pdf') {
        return (
            <>
                <iframe
                    src={link}
                    title={filename}
                    width="70%"
                    height="630px"
                    seamless
                    className="pb-4 mx-auto"
                />
            </>
        )
    }
    if (fileExtension === 'mp4' || fileExtension === 'mov') {
        const videoJsOptions = {
            autoplay: 'muted',
            preload: 'auto',
            controls: true,
            responsive: true,
            fluid: true,
            playbackRates: [0.5, 1, 1.5, 2],
            sources: [
                {
                    src: link,
                    type: 'video/mp4',
                },
            ],
        }

        const handlePlayerReady = (player) => {
            playerRef.current = player

            // player.on('waiting', () => {
            //     videojs.log('player is waiting')
            // })

            // player.on('dispose', () => {
            //     videojs.log('player will dispose')
            // })
        }
        return (
            <VideojsPlayer
                options={videoJsOptions}
                onReady={handlePlayerReady}
                className="video-js-player"
            />
        )
    }
}

export default PreviewLink
