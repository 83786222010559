import './RenderHTMLContent.scss'

const RenderHTMLContent = ({ htmlContent }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className="default-styles"
        />
    )
}

export default RenderHTMLContent
