const Contact = ({ contact }) => {
    return (
        <div className="flex flex-col items-start flex-grow md:basis-1/3 basis-full sm:basis-1/2">
            <a href={`mailto:${contact.email}`}>
                <h4 className="text-lg font-bold leading-normal text-justify capitalize font-wbs text-wbyellow">
                    {contact.last_name}, {contact.first_name}
                </h4>
                <p className="text-sm text-justify text-white capitalize font-wbs">
                    {contact.title}
                </p>
            </a>
        </div>
    )
}

export default Contact
