import { Link } from 'react-router-dom'
import DefaultTextOverlay from 'pages/franchise/components/DefaultTextOverlay/DefaultTextOverlay'
import { sortAlphabetically } from 'utils/utils'
import RenderHTMLContent from 'pages/brand-franchisePage/components/RenderHTMLContent/RenderHTMLContent'

const PropertiesContent = ({ content }) => {
    const properties = content?.data?.children
    const groupedProperties = properties?.reduce((grouped, property) => {
        let { media_type } = property
        if (media_type === null) {
            media_type = 'misc'
        }
        if (!grouped[media_type]) {
            grouped[media_type] = []
        }

        grouped[media_type].push(property)

        return grouped
    }, {})

    const sortedGroupedProperties = Object.entries(groupedProperties).sort(
        ([categoryA], [categoryB]) => {
            if (categoryA === 'misc') return 1
            if (categoryB === 'misc') return -1
            return categoryA.localeCompare(categoryB)
        }
    )

    const renderRow = ({ title, id, slug, featured_image }) => {
        return (
            <div
                key={id}
                className="h-[175px] w-[117px] relative hover:opacity-90"
            >
                <Link to={slug}>
                    <img
                        src={featured_image.source}
                        alt={featured_image.alt}
                        className="object-cover w-full h-full"
                    ></img>
                    {featured_image.source?.endsWith(
                        'default_featuredImage.png'
                    ) && <DefaultTextOverlay fontSize={10} name={title} />}
                </Link>
            </div>
        )
    }
    const renderRows = ([mediaType, properties]) => {
        console.log(mediaType, properties)

        if (!properties) return null
        return (
            <div
                className="flex flex-col items-start gap-2.5 self-stretch"
                key={mediaType}
            >
                <h3 className="self-stretch text-xl font-extrabold text-white uppercase font-openSans">
                    {mediaType}
                </h3>
                <div className="flex items-start gap-2.5 flex-wrap content-start self-stretch">
                    {sortAlphabetically(properties).map((property) =>
                        renderRow(property)
                    )}
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col items-start self-stretch bg-white/5 sm:px-14 md:px-[60px] md:pb-[60px] pt-10 gap-[30px]">
            <div className="flex flex-col items-start self-stretch">
                <h2 className="flex flex-col self-stretch justify-center text-3xl font-extrabold text-white uppercase font-openSans h-14">
                    Properties
                </h2>
                <div className="self-stretch text-xl text-justify text-white font-inter">
                    <RenderHTMLContent
                        htmlContent={content?.structured?.properties}
                    />
                </div>
            </div>
            {properties?.length > 0 && (
                <div className="flex flex-col items-start self-stretch gap-y-[60px]">
                    {sortedGroupedProperties.map((groupedProperties) =>
                        renderRows(groupedProperties)
                    )}
                </div>
            )}
        </div>
    )
}

export default PropertiesContent
