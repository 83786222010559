import { Link } from 'react-router-dom'

const PageAndFileLinks = ({
    date,
    file_type,
    file_url,
    id,
    name,
    slug,
    type,
}) => {
    const isPowerpoint = (url) => {
        if (!url) return false
        return (
            url.includes('.ppt') ||
            url.includes('.pptx') ||
            url.includes('presentation')
        )
    }
    const download_file = async (fileURL) => {
        const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1)

        let response = await fetch(fileURL)
        let blobData = await response.blob()
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blobData)
        a.setAttribute('download', filename)
        a.click()
    }


    const generateLink = () => {
        return (
            <div className="flex sm:flex-row flex-col gap-2.5">
                {type !== 'link' && (
                    <button
                        onClick={() => {
                            download_file(file_url)
                        }}
                        className="flex h-7 px-4 items-center rounded-[4px] bg-wbyellow hover:opacity-80"
                    >
                        Download PTT
                    </button>
                )}
            </div>
        )
    }
    const getLink =
        type === 'link'
            ? slug
            : file_type === 'png' || file_type === 'jpg' || file_type === 'jpeg'
            ? `${window.location.pathname}/preview?p=${encodeURIComponent(
                  file_url
              )}`
            : !isPowerpoint(file_type) && file_type !== 'png'
            ? `${window.location.pathname}/preview?p=${encodeURIComponent(
                  file_url
              )}`
            : false

    return (
        <div
            key={id}
            className="flex py-[5px] items-center gap-[30px] self-stretch border-b border-[#999] justify-between"
        >
            {isPowerpoint(file_type) ? (
                <>
                    <p onClick={() => {
                        download_file(file_url)
                    }} className="text-sm sm:text-base text-wbyellow font-openSans font-extrabold leading-[30px] tracking-[0.8px] hover:text-lightwbyellow cursor-pointer">
                        {name} <span className="font-normal">- ({date})</span>
                    </p>
                    {generateLink()}
                </>
            ) : (
                <Link to={getLink} target="_blank">
                    <p className="text-sm sm:text-base text-wbyellow font-openSans font-extrabold leading-[30px] tracking-[0.8px] hover:text-lightwbyellow">
                        {name} <span className="font-normal">- ({date})</span>
                    </p>
                </Link>
            )}
        </div>
    )
}

export default PageAndFileLinks
