import WbLogo from '__shared/images/icons/wb-logo.png'
import { classNames } from 'utils/utils'
export default function Footer({ className }) {
    return (
        <footer
            className={classNames(
                'text-sm font-openSans py-9 px-10 max-w-8xl ',
                className
            )}
        >
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="flex flex-col justify-between pt-[30px] mx-auto text-xs border-t border-[#757575] sm:flex-row gap-y-4 gap-x-6">
                <div className="flex items-center gap-x-6 font-inter">
                    <div>
                        <img
                            src={WbLogo}
                            alt="Warner Bros Discovery shield logo"
                            className="object-contain w-full h-[31px]"
                        />
                    </div>
                    <p className="leading-4.5 text-xs sm:text-sm">
                        TM &amp; &copy; 2023 Warner Bros. Entertainment Inc. All
                        rights reserved.
                    </p>
                </div>
                <div className="flex items-center mx-auto text-xs sm:text-sm sm:mx-0 gap-x-8 font-inter">
                    <div>
                        <a
                            className="leading-4.5 hover:opacity-75"
                            target="_blank"
                            href="https://policies.warnerbros.com/terms/en-us/html/terms_en-us_1.2.0.html"
                        >
                            Terms of Use
                        </a>
                    </div>
                    <div>
                        <a
                            className="leading-4.5 hover:opacity-75"
                            target="_blank"
                            href="https://policies.warnerbros.com/privacy/"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
