// import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
// import { authAtom } from 'utils/atoms'
import { useCookies } from 'react-cookie'

const useApi = (endpoint, method = 'get', headers, body) => {
    const [responseJSON, setResponseJSON] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['authToken'])
    const [restrictedContentError, setRestrictedContentError] = useState(false)

    useEffect(() => {
        // flag to cancel if a component unmounts during fetch
        let shouldCancel = false

        const callFetch = async () => {
            setIsLoading(true)
            setError(false)
            const defaultHeaders = {
                Authorization: `Bearer ${cookies.authToken}`,
                'Content-Type': 'application/json',
                origin: window.location.host,
            }
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_FRANCHISE_PROXY_URL}/${endpoint}`,
                    {
                        method,
                        body: JSON.stringify(body),
                        credentials: 'include',
                        headers: {
                            ...defaultHeaders,
                            ...headers,
                        },
                    }
                )
                if (
                    response.status === 401 ||
                    (response.status === 500 &&
                        response.message ===
                            'Cannot fetch Franchises: Auth failed')
                ) {
                    // remove token to prevent loop in login
                    // setAuthToken(null)
                    removeCookie('authToken', {
                        path: '/',
                        maxAge: 36000,
                        sameSite: 'none',
                        secure: true,
                        ...(window.location.hostname !== 'localhost' && {
                            domain: 'warnerbros.com',
                        }),
                    })

                    window.location.href = '/login'
                }
                if (response.status === 500) {
                    setError(true)
                }
                const newResponseJSON = await response.json()
                if (shouldCancel) return

                if (response.status === 403) {
                    setRestrictedContentError(true)
                } else {
                    setResponseJSON(newResponseJSON)
                    setRestrictedContentError(false)
                }
            } catch (newError) {
                if (shouldCancel) return
                setError(newError)
                setResponseJSON(null)
            }

            setIsLoading(false)
        }

        callFetch()

        return () => (shouldCancel = true)
    }, [endpoint])

    return { responseJSON, error, isLoading, restrictedContentError }
}

export default useApi
