import { useEffect } from 'react'
import useApi from 'hooks/useApi'
import './BrandFranchisePage.scss'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import BrandFranchiseHero from './components/BrandFranchiseHero/BrandFranchiseHero'
import RestrictedContent from 'components/RestrictedContent/RestrictedContent'
import { useAtom } from 'jotai'
import { showEditButtonAtom } from 'utils/atoms'
import ErrorPage from 'pages/error/ErrorPage'
import { htmlDecode } from 'utils/utils'
import FeaturedSlider from 'components/FeaturedSlider/FeaturedSlider'
import Collections from 'components/Collections/Collections'
import FranchisesCards from './components/FranchisesCards/FranchisesCards'
import BrandsFranchisesResourcesTabs from './components/BrandsFranchisesResourcesTabs/BrandsFranchisesResourcesTabs'
import PropertiesContent from './components/BrandsFranchisesResourcesTabs/TabInfo/PropertiesContent/PropertiesContent'

const BrandFranchisePage = () => {
    const location = useLocation()

    const { property, franchise, brand } = useParams()
    const [showEditButton, setShowEditButton] = useAtom(showEditButtonAtom)
    const {
        responseJSON: content,
        isLoading: contentIsLoading,
        error: contentError,
        restrictedContentError,
    } = useApi(
        `page?slug=${encodeURIComponent(
            location.pathname.replace('/franchise/', '')
        )}&${location.search.replace('?', '')}`,
        'get'
    )

    content.post_title = htmlDecode(content.post_title)

    useEffect(() => {
        const handleKeydown = (event) => {
            if (
                event.shiftKey &&
                (event.ctrlKey || event.metaKey) &&
                event.key === 'E'
            ) {
                setShowEditButton((prev) => !prev)
            }
        }

        window.addEventListener('keydown', handleKeydown, false)

        return () => {
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [showEditButton])

    // Display an full screen empty div with same background along with nav and footer until content loads
    if (content.length < 1 && contentIsLoading)
        return <div className="bg-wbslate min-h-franchise-page"></div>

    // Display an full screen empty div with same background along with nav and footer until content loads
    if (contentIsLoading)
        return <div className="bg-wbslate min-h-franchise-page"></div>

    // Display an 404 error page when there is no content
    if (contentError) {
        return <ErrorPage className="bg-wbslate" />
    }

    return (
        <>
            {restrictedContentError ? (
                <main className="flex flex-col flex-grow h-full pt-24 bg-wbslate brand-page">
                    <RestrictedContent />
                </main>
            ) : (
                <main className="flex flex-col items-start flex-grow h-full brand-page bg-[#080918] pb-[86px] gap-y-[30px]">
                    {/* HERO  */}
                    <BrandFranchiseHero
                        content={content}
                        contentIsLoading={contentIsLoading}
                    />

                    <div className="w-full px-4 sm:px-10 gap-[50px] flex flex-col">
                        {/* FEATURED  */}
                        {content.data?.featured?.length > 0 &&
                            content.structured?.show_featured && (
                                <FeaturedSlider
                                    className=""
                                    featured={content.data?.featured}
                                />
                            )}

                        {/* TABS  */}
                        <BrandsFranchisesResourcesTabs content={content} />

                        {/* FRANCHISES  */}
                        {!franchise && content?.data?.children.length > 0 && (
                            <FranchisesCards
                                brand={content.post_title}
                                franchises={content?.data?.children}
                            />
                        )}

                        {/* PROPERTIES */}
                        {!property && franchise && (
                            <PropertiesContent content={content} />
                        )}

                        {/* COLLECTIONS  */}
                        {content?.structured?.show_collections &&
                            content?.structured?.collections.length > 0 && (
                                <section
                                    style={{
                                        '--image-url': `url(${content?.structured?.introduction_background})`,
                                    }}
                                    className="py-5 before:bg-[image:var(--image-url)] before:bg-cover before:bg-center flex flex-col items-start self-strech bg-cover relative before:block before:absolute before:inset-0"
                                >
                                    <div className="absolute inset-0 blur-container "></div>

                                    <Collections
                                        titlePadding="30px"
                                        collections={
                                            content?.structured?.collections
                                        }
                                    />
                                </section>
                            )}
                    </div>
                </main>
            )}
        </>
    )
}

export default BrandFranchisePage
