import { Link } from 'react-router-dom'
import { sortAlphabetically } from 'utils/utils'
const FranchisesCardList = ({ franchises }) => {
    const alphabeticallySortedFranchises = sortAlphabetically(franchises)

    return (
        <div className="flex flex-wrap items-start content-start gap-x-[30px] gap-y-[53px] self-stretch justify-center ">
            {alphabeticallySortedFranchises?.map(
                ({ id, title, slug, featured_image: { source, alt } }) => {
                    const isDefaultImg = source.endsWith(
                        'default_featuredImage.png'
                    )
                    return (
                        <Link to={slug} key={id}>
                            <div className="overflow-hidden flex flex-col items-start border-white border-[5px] w-[200px] h-[300px] relative hover:opacity-[.85]">
                                <img
                                    src={source}
                                    alt={alt}
                                    className={`${
                                        isDefaultImg ? 'pb-16' : 'r'
                                    } w-full h-full card-img object-cover`}
                                />
                                <div className="absolute bottom-0 flex flex-col items-start w-[200px] ">
                                    <div className="w-[256px] h-7">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="257"
                                            height="29"
                                            viewBox="0 0 257 29"
                                            fill="none"
                                        >
                                            <path
                                                d="M200.5 28.1699H0.5L200.5 0.169922V28.1699Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <div className="flex w-[200px] h-[70px] p-2.5 flex-col justify-center items-center gap-2.5 bg-white">
                                        <p className="items-center font-bold text-center uppercase font-openSans tracking-wbwidest">
                                            {title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                }
            )}
        </div>
    )
}

export default FranchisesCardList
