import { Outlet } from 'react-router-dom'
import Footer from 'components/Footer/Footer'
import FranchiseTopbar from '../FranchiseTopbar/FranchiseTopbar.js'
import SlimFooter from 'components/SlimFooter/SlimFooter.js'

export default function FranchiseBase() {
    return (
        <div className="relative flex flex-col flex-grow w-full mx-auto bg-black franchise-hub max-w-8xl">
            <FranchiseTopbar />
            <Outlet />
            {/* <Footer className="w-full text-white bg-wbslate" /> */}
            <SlimFooter />
        </div>
    )
}
