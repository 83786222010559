import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import './SearchPage.scss'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Filters from './components/Filters/Filters'
import { useAtom } from 'jotai'
import { loadableNavAtom, loadableFranchiseHomeAtom } from 'utils/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faLink } from '@fortawesome/free-solid-svg-icons'
import { flat } from 'utils/utils'

const SearchPage = () => {
    const location = useLocation()
    const [searchTerm, setSearchTerm] = useState('')
    const navigate = useNavigate()
    const [searchResults, setSearchResults] = useState([])
    const [franchiseHomeAtom] = useAtom(loadableFranchiseHomeAtom)
    const [franchiseNavAtom] = useAtom(loadableNavAtom)
    const [filter, setFilter] = useState('all')

    useEffect(() => {
        if (!franchiseNavAtom.data) return
        const term = decodeURIComponent(
            new URLSearchParams(location.search).get('query')
        )

        setSearchTerm(term)

        if (term < 2) {
            setSearchResults([])
            return
        }

        const searchableNav = flat(franchiseNavAtom?.data)

        const results = searchableNav.filter((i) => {
            const regex = new RegExp(
                '(?=.*' + term.split(/\W+/g).join(')(?=.*') + ')',
                'i'
            )

            return regex.test(i.title)
        })

        setSearchResults(results)
    }, [location.search, franchiseNavAtom.data])

    const handleFilter = (selectedFilter) => {
        setFilter(selectedFilter)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (searchTerm.length >= 2) {
            navigate(
                `/franchise/search?query=${encodeURIComponent(searchTerm)}`
            )
        }
    }

    return (
        <div className="flex flex-col items-start w-full bg-wbslate ">
            {/* HERO */}
            {franchiseHomeAtom.data?.structured?.introduction_background && (
                <section
                    className="hero flex h-[300px] items-center gap-2.5 self-stretch relative "
                    style={{
                        backgroundImage: `url(${franchiseHomeAtom.data?.structured?.introduction_background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Background overlay with 60% opacity */}
                    <div className="absolute inset-0 opacity-60 bg-wbslate"></div>

                    <div className="flex sm:px-12.5 flex-col items-center flex-grow flex-shrink-0 basis-0 text-white relative isolate z-10">
                        <h1 className="text-5xl font-extrabold text-center uppercase font-openSans flex w-[637px] h-[118px] flex-col justify-center z-40">
                            Search Results
                        </h1>
                    </div>
                </section>
            )}

            {/* BODY */}
            <section className="flex pt-12.5 px-10 flex-col items-center self-stretch">
                {/* BREADCRUMBS */}
                <div className="flex items-center gap-2.5 self-stretch bg-white border-b border-[#999] h-20">
                    <Breadcrumbs
                        className="pl-[25px] text-black text-lg uppercase font-bold leading-[80px]"
                        title="search results"
                    />
                </div>

                {/* CONTENT CONTAINER */}
                <div className="flex py-10 px-[30px] items-center justify-end self-stretch border-b border-[#999] bg-white">
                    {/* SIDENAV FILTERS */}
                    <Filters
                        filter={filter}
                        searchResults={searchResults}
                        handleFilter={handleFilter}
                    />

                    {/* CONTENT */}
                    <div className="flex flex-col items-start self-start flex-grow flex-shrink-0 gap-10 px-10 bg-white basis-0">
                        {/* SEARCH CONTAINER */}
                        <div className="flex flex-col items-center self-stretch border-b border-[#B8B8B8]">
                            <form
                                className="flex items-center self-stretch gap-5 pb-10"
                                onSubmit={handleSubmit}
                            >
                                <input
                                    type="text"
                                    className="flex p-2.5 items-start gap-2.5 flex-grow flex-shrink-0 basis-0 rounded-[10px] bg-lightergray text-[28px] font-wbs leading-9"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value)
                                    }}
                                />
                                <button
                                    type="submit"
                                    className={`flex h-14 p-[10px] justify-center items-center gap-[10px] rounded-[10px]  text-white text-2xl font-bold font-wbs 
                                    ${
                                        searchTerm.length < 2
                                            ? 'bg-gray-300 cursor-not-allowed'
                                            : 'bg-blue hover:opacity-90'
                                    }`}
                                    disabled={searchTerm.length < 2}
                                >
                                    <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        className="font-black text-white text-2xl leading-4 tracking-[1.2px] uppercase"
                                    />
                                    Search
                                </button>
                            </form>
                        </div>

                        {/* SEARCH RESULTS */}
                        <div className="flex flex-col items-center self-stretch">
                            <div className="flex px-2.5 flex-col items-start gap-10 self-stretch">
                                {/* RESULTS */}
                                {searchResults.length === 0 ? (
                                    <p className="text-xl font-wbs">
                                        No results found for your search term.
                                        Please try again with a different
                                        keyword.
                                    </p>
                                ) : (
                                    searchResults
                                        .filter(
                                            (result) =>
                                                filter === 'all' ||
                                                result.type === filter
                                        )
                                        .map(({ title, id, slug }) => (
                                            <div
                                                className="flex flex-col items-start gap-2.5 self-stretch"
                                                key={id}
                                            >
                                                <div className="flex flex-col items-start gap-[2px] self-stretch">
                                                    <h2 className="text-midblue font-wbs text-[28px] font-bold leading-9">
                                                        {title}
                                                    </h2>
                                                    <div>
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            className="pr-1 text-base font-black leading-5 text-blue link-icon"
                                                        />
                                                        <Link
                                                            to={slug}
                                                            className="text-xl leading-6 text-blue font-wbs"
                                                        >
                                                            {slug}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SearchPage
